import './app.scss';
import 'lazysizes';
// import 'intersection-observer';

// if you need react, just enable it by commenting in this line
// import react from './react/index';

(function () {
    // if you need react, just enable it by commenting in this line
    // react.init();

    const questionsFaq = document.getElementsByClassName('question-faq');
    if (questionsFaq.length) {
        import('./ts/accordion').then((accordionEntry) => {
            accordionEntry.default.init();
        });
    }

    const menu = document.getElementById('header-menu');
    if (menu) {
        import('./ts/header').then((header) => {
            header.default.init();
        });
    }

    const aosElements = document.querySelectorAll('[data-aos]');
    if (aosElements.length) {
        import('./ts/aos').then((aos) => {
            aos.default.init();
        });
    }

    const formOverlayContainers = document.querySelectorAll(
        '.form-overlay-container'
    ) as NodeListOf<HTMLElement>;
    if (formOverlayContainers.length) {
        import('./ts/form-overlay').then((formOverlay) => {
            formOverlayContainers.forEach((container) => {
                formOverlay.default.init(container);
            });
        });
    }

    const formOverlayContainersMobile = document.querySelectorAll(
        '.form-overlay-container-mobile'
    ) as NodeListOf<HTMLElement>;
    if (formOverlayContainersMobile.length) {
        import('./ts/form-overlay-mobile').then((formOverlay) => {
            formOverlayContainersMobile.forEach((container) => {
                formOverlay.default.init(container);
            });
        });
    }

    // Init all swiper if there are some
    const allSwiper = document.querySelectorAll(
        '.swiper-container'
    ) as NodeListOf<HTMLElement>;
    if (allSwiper.length) {
        import('./ts/swiper').then((swiper) => {
            allSwiper.forEach(function (swiperElement) {
                if (!swiperElement.dataset.swiperOptions) return;
                // Set swiper element and swiper options from data-attribute
                swiper.default.init(
                    swiperElement,
                    JSON.parse(swiperElement.dataset.swiperOptions)
                );
            });
        });
    }

    const formsFooter = document.querySelectorAll('div[data-form-id]');
    if (formsFooter.length) {
        import('./ts/footer').then((form) => {
            form.default.init();
        });
    }

    // no element request because element will be generated via js
    import('./ts/cookie-banner').then((cookieBanner) => {
        cookieBanner.default.init();
    });

    const youtubeVideoContainers = document.querySelectorAll(
        '.youtube-video-container'
    ) as NodeListOf<HTMLElement>;
    if (youtubeVideoContainers.length) {
        import('./ts/youtube-video').then((youtubeVideo) => {
            youtubeVideoContainers.forEach((container) => {
                youtubeVideo.default.init(container);
            });
        });
    }

    const customerFeedbackSlider = document.querySelectorAll(
        '.customers-feedback-section'
    ) as NodeListOf<HTMLElement>;
    if (customerFeedbackSlider.length) {
        import('./ts/customer-feedback-slider').then((slider) => {
            customerFeedbackSlider.forEach((container) => {
                slider.default.init(container);
            });
        });
    }
})();
